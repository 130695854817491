import React, { useState } from "react";
import { Table } from "semantic-ui-react";
import { UsersDisplayComponentProps } from "../User/Domain/Interfaces/UsersDisplayComponentProps";
import { observer } from "mobx-react";
import { useAmzAtlasContext } from "../AmzAtlasContext/AmzAtlasContext";
import ErrorDisplay from "../Messaging/ErrorDisplay";
import UsersDisplayCells from "./UsersDisplayCells";
import UsersDisplayEdit from "./UsersDisplayEdit";

const UsersDisplayComponent: React.FC<UsersDisplayComponentProps> = ({
  users,
}) => {

  const [editIndex, setEditIndex] = useState(-1);
  const { usersStore } = useAmzAtlasContext();
  const [errorMessage, setErrorMessage] = useState("")

  const onEditClick = (index: number) => {
    setEditIndex(index);
  }

  const onSaveClick = (index: number) => {
    setErrorMessage('');
    try {
      usersStore.updateUser(users[index]);
      setEditIndex(-1);
    }
    catch (error) {
      setErrorMessage(error.message);
    }
  }

  const onCancelClick = (index: number) => {
    setEditIndex(-1);
  }

  const onDeleteClick = (index: number) => {
    setErrorMessage('');
    if (!usersStore.users) {
      return;
    }

    try {
      const userToDelete = users[index];

      usersStore.deleteUser(userToDelete);
    } catch (error) {
      console.log("CUAGT")
      setErrorMessage(error.message);
    }
  }

  return (
    <>
      <ErrorDisplay message={errorMessage} />
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>First Name</Table.HeaderCell>
            <Table.HeaderCell>Last Name</Table.HeaderCell>
            <Table.HeaderCell>Company Id</Table.HeaderCell>
            <Table.HeaderCell>Role</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {users.map((user, i) => (
            <>
            {console.log(`users-row-${i}`)}
              <Table.Row key={`users-row-${i}`}>
                {editIndex === i ?
                  <UsersDisplayEdit index={i} onCancelClick={onCancelClick} onSaveClick={onSaveClick} user={user} currentUser={usersStore.currentUser} /> :
                  <UsersDisplayCells index={i} onDeleteClick={onDeleteClick} onEditClick={onEditClick} user={user} />
                }
              </Table.Row>
            </>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

export default observer(UsersDisplayComponent);
