import React, { useState } from 'react';
import {
  Authenticator,
  ConfirmSignIn,
  ForgotPassword,
  Greetings,
  RequireNewPassword,
  SignIn,
  SignOut,
  VerifyContact,
  Loading,
} from 'aws-amplify-react';
import logo from '../../logo.png';
import { Grid } from 'semantic-ui-react';
import { Auth } from 'aws-amplify';
import IdleTimer from 'react-idle-timer';
import { Checkbox } from 'semantic-ui-react';
import authStorage from '../../AuthenticationStorage';
import AmzBackground from '../AmzBackground';

const AmzAtlasAuthenticator: React.FC<any> = (props) => {
  const [authState, setAuthState] = useState('');
  const [rememberMe, setRememberMe] = useState(
    localStorage.getItem('remember-me') === 'true'
  );

  const authStateChange = (authState: string) => {
    setAuthState(authState);
  };

  const onIdle = () => {
    if (!rememberMe) {
      Auth.signOut();
    }
  };

  const onRememberMeChange = (_: any, data: any) => {
    setRememberMe(data.checked);

    authStorage.setRememberMe(data.checked);
    localStorage.setItem('remember-me', data.checked);
  };

  const rememberMeCheckbox = () => {
    return (
      <div
        style={{
          position: 'relative',
          zIndex: 2,
          top: '200px',
          right: '135px',
          display: 'inline-block',
          color: '#828282',
          fontSize: '13px',
        }}
      >
        <Checkbox
          label={
            <label
              style={{
                color: '#828282',
                fontSize: '13px',
                fontFamily: '"Helvetica Neue", sans-serif',
              }}
            >
              Remember me
            </label>
          }
          onChange={onRememberMeChange}
          checked={rememberMe}
          data-testid='remember me'
          style={{
            color: '#828282',
            fontSize: '12px',
            fontFamily: '"Helvetica Neue", sans-serif',
          }}
          color={'#828282'}
        />
      </div>
    );
  };

  if (authState !== 'signedIn') {
    const imageStyle = { width: 380, margin: 'auto', display: 'flex' };
    const authProps = { ...props };
    delete authProps.children;
    return (
      <>
        <AmzBackground>
          <div
            style={{
              backgroundColor: 'rgba(255,255,255)',
              width: 430,
              borderRadius: 15,
              margin: '100px auto 0 auto',
            }}
          >
            <Grid columns={1}>
              <Grid.Column textAlign='center'>
                <img src={logo} alt='amz-atlas-logo' style={imageStyle} />
                {authState === 'signIn' ? rememberMeCheckbox() : null}
                <Authenticator
                  {...authProps}
                  onStateChange={authStateChange}
                  hideDefault={true}
                >
                  <SignIn />
                  <SignOut />
                  <ConfirmSignIn />
                  <ForgotPassword />
                  <Loading />
                  <RequireNewPassword />
                  <SignOut />
                  <VerifyContact />
                  <Greetings />
                </Authenticator>
              </Grid.Column>
            </Grid>
          </div>
        </AmzBackground>
      </>
    );
  } else {
    return (
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <IdleTimer onIdle={onIdle} timeout={1000 * 60 * 30} startOnMount />
        {props.children}
      </div>
    );
  }
};

export default AmzAtlasAuthenticator;
