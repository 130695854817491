import { applySnapshot, destroy, flow, getEnv, Instance, types } from 'mobx-state-tree';
import { IUser, IUserSnapshot, User } from '../models/User';
import { UserService } from '../services/User/UserService';

export const UsersStore = types
  .model('UsersStore', {
    currentUser: types.maybe(User),
    isLoading: types.boolean,
    users: types.maybe(types.array(User))
  })
  .actions((self) => {
    const userService: UserService = getEnv(self).userService;

    const wrapLoadingDuringAction = flow(function* (serviceAction: any) {
      self.isLoading = true;
      try {
        yield serviceAction();
      } catch (err) {
        throw err;
      } finally {
        self.isLoading = false;
      }
    });

    return {
      clear: () => {
        self.currentUser = undefined;
        self.users = undefined;
      },
      loadUser: flow(function* () {
        yield wrapLoadingDuringAction(
          flow(function* () {
            const user = yield userService.getUser();
            self.currentUser = user;
          })
        );
      }),
      loadAllUsers: flow(function* () {
        yield wrapLoadingDuringAction(
          flow(function* () {
            const users = yield userService.getAllUsers();
            self.users = users;
          })
        );
      }),
      loadUsersForCompany: flow(function* (companyId) {
        yield wrapLoadingDuringAction(
          flow(function* () {
            const users = yield userService.getUsersForCompany(companyId);
            self.users = users;
          })
        );
      }),
      createUser: flow(function* (user: IUserSnapshot) {
        yield wrapLoadingDuringAction(
          flow(function* () {
            const createdUser = yield userService.createUser(user);
            self.users?.push(createdUser);
          })
        )
      }),
      updateUser: flow(function* (user: IUser) {
        yield wrapLoadingDuringAction(
          flow(function* () {
            const updatedUser = yield userService.updateUser(user);
            applySnapshot(user, updatedUser);
          })
        )
      }),
      deleteUser: flow(function* (user: IUser) {
        yield wrapLoadingDuringAction(
          flow(function* () {
            yield userService.deleteUser(user.id);
            destroy(user);
          })
        )
      })
    };
  });

export interface IUsersStore extends Instance<typeof UsersStore> {}
