import React from 'react';
import { Loader, Dimmer } from 'semantic-ui-react';

const loader: React.FC<{active: boolean, children: any}> = (props) => {
    const { active } = props;
    return (
        <Dimmer.Dimmable>
            <Dimmer active={active} inverted>
                <Loader />
            </Dimmer>
            {props.children}
        </Dimmer.Dimmable>
    );
}
export default loader;
