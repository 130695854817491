import React from 'react';
import { observer } from 'mobx-react-lite';

import { Segment } from 'semantic-ui-react';
import { IUsersStore } from '../../stores/UsersStore';
import PowerBiWrapper from './PowerBiWrapper';

interface PowerBiLandingPageProps {
  usersStore: IUsersStore;
}

const PowerBiLandingPage: React.FC<PowerBiLandingPageProps> = ({
  usersStore,
}) => {

  return (
    <Segment style={{ padding: '0' }}>
      <PowerBiWrapper id={usersStore.currentUser?.companyId || ''} />
    </Segment>
  );
};

export default observer(PowerBiLandingPage);
