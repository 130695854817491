import React from 'react';
import { Segment } from 'semantic-ui-react';

interface PowerBiWrapperProps {
  id: string;
}

const PowerBiWrapper: React.FC<PowerBiWrapperProps> = ({ id }) => {

  if (!id.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/)) {
    return <>
        <Segment>Company Id not set correctly for user.</Segment>
    </>
  }

  const url = `https://app.powerbi.com/reportEmbed?reportId=${id}&autoAuth=true&ctid=a18adcf8-5b0d-4db1-bd67-824762497c4b&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXdlc3QtdXMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D`;
  return (
    <>
      <iframe
        src={url}
        title='Power BI Frame'
        style={{
          width: '100%',
          height: 'calc(100vh - 110px)',
          padding: '0',
          margin: '0',
          border: 'none',
        }}
      />
    </>
  );
};

export default PowerBiWrapper;
