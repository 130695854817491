import React from 'react';
import { observer } from 'mobx-react-lite';
import AmzAtlasHeader from '../Components/Layout/AmzAtlasHeader';
import UserManagmetComponent from '../Components/User/UserManagmetComponent';

const UserManagement: React.FC = () => {
  return (
    <>
      <AmzAtlasHeader />
      <UserManagmetComponent />
    </>
  );
};

export default observer(UserManagement);
