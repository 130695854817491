import { Instance, SnapshotIn, types } from "mobx-state-tree";

export const User = types
  .model({
    id: types.string,
    role: types.enumeration([
      'Company Executive',
      'Atlas Executive',
      'Standard User',
      'Atlas User',
    ]),
    firstName: types.string,
    lastName: types.string,
    companyId: types.string,
    email: types.string,
  })
  .views((self) => ({
    get isExecutive() {
      return (
        self.role === 'Company Executive' || self.role === 'Atlas Executive'
      );
    },
    get isAtlasUser() {
      return self.role === 'Atlas User';
    },
    get isStandardUser() {
      return self.role === 'Standard User';
    },
    get isCompanyExecutive() {
      return self.role === 'Company Executive';
    },
    get isAtlasExecutive() {
      return self.role === 'Atlas Executive';
    },
    get canManageUsers() {
      return self.role === 'Atlas User' || self.role === 'Company Executive' || self.role === 'Atlas Executive';
    },
    get isLoaded() { 
      return !!self.id
    }
  }))
  .actions((self: any) => ({
    updateFromInputEvent(e: React.ChangeEvent<HTMLInputElement>) {
      self[e.target.name] = e.target.value;
    },
    updateFromDropdownEvent(data: any) {
      self[data.name] = data.value;
    },
  }));

export interface IUser extends Instance<typeof User> {}
export interface IUserSnapshot extends SnapshotIn<typeof User> {}
