import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AmzAtlasContext } from './Components/AmzAtlasContext/AmzAtlasContext';
import Routes from './Components/Routes/Routes';


function App() {
  return (
    <>
      <AmzAtlasContext>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
      </AmzAtlasContext>
    </>
  );
}

export default App;
