import { Auth } from 'aws-amplify';
import { IUserSnapshot } from '../../models/User';
import axios from 'axios';

export class UserService {
  private mapCognitoUserToSnapshot(cognitoUser: any) {
    const user = {
      id: cognitoUser.attributes.sub,
      firstName: cognitoUser.attributes.given_name || "",
      lastName: cognitoUser.attributes.family_name || "",
      companyId: cognitoUser.attributes.name || "",
      role: cognitoUser.signInUserSession.accessToken.payload['cognito:groups'][0].replace("_", " "),
      email: cognitoUser.attributes.email || "" 
    };
    return user;
  }

  public getUser = async (): Promise<any> => {
    const currentUser = await Auth.currentAuthenticatedUser({bypassCache: true});

   return this.mapCognitoUserToSnapshot(currentUser);
  };

  public getAllUsers = async (): Promise<any> => {
    const result = await axios.get('/users')
    
    return result.data;
  };

  public getUsersForCompany = async (companyId: string): Promise<IUserSnapshot[]> => {
    const result = await axios.get(`/users?companyId=${companyId}`)
    
    return result.data;
  };

  public createUser = async (user: IUserSnapshot): Promise<IUserSnapshot> => {
    const result = await axios.post(`/users`, user);

    return result.data;
  }

  public updateUser = async(user: IUserSnapshot): Promise<IUserSnapshot> => {
    const userId = user.id;
  
    const result = await axios.patch(`/users/${userId}`, user);
    
    return result.data;
  }

  public deleteUser = async(userId: string): Promise<void> => {  
    await axios.delete(`/users/${userId}`);
  }

  public checkSubscription = async (): Promise<any> => {
    const currentUser = await Auth.currentAuthenticatedUser();

    const result = await fetch(
      `/subscription/status/${currentUser.username}`,
      {
        mode: 'cors',
        method: 'get',
        body: JSON.stringify({
          userId: currentUser.username,
        }),
      }
    );

    if (result.status === 200) {
      const parsedResponse = await result.json();
      return {
        customerId: parsedResponse.customerId,
        paymentMethodId: parsedResponse.paymentMethodId,
        subscriptionId: parsedResponse.subscriptionId,
        subscriptionItemId: parsedResponse.subscriptionItemId,
        id: parsedResponse.userId,
      };
    } else {
      return {
        customerId: undefined,
        paymentMethodId: undefined,
        subscriptionId: undefined,
        subscriptionItemId: undefined,
        id: currentUser.username,
      };
    }
  };
}
