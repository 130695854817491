import React, { useEffect, useState } from 'react';
import { Loader } from 'semantic-ui-react';
import { AxiosInitializer } from '../../AxiosInitializer';
import { useAmzAtlasContext } from '../AmzAtlasContext/AmzAtlasContext';

export const UserInitializer: React.FC = (props) => {
  const { usersStore } = useAmzAtlasContext();

  const [isInitializing, setIsInitializing] = useState(false);

  useEffect(() => {
    const loadUserPromise = usersStore.loadUser();
    const axiosInitializerPromise = AxiosInitializer();
    Promise.all([loadUserPromise, axiosInitializerPromise])
      .then(() => {
        if (usersStore.currentUser?.isAtlasExecutive || usersStore.currentUser?.isAtlasUser) {
          return usersStore.loadAllUsers();
        } else if (usersStore.currentUser?.isCompanyExecutive) {
          return usersStore.loadUsersForCompany(usersStore.currentUser.companyId);
        }
      }).catch(e => {
        console.error(e)
      })
      .finally(() => {
        setIsInitializing(false);
      });
  });

  return isInitializing ? <Loader active /> : null;
};
