import { Auth } from 'aws-amplify';
import axios from 'axios';

export const AxiosInitializer = async () => {

    axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

    axios.interceptors.request.use(async (config) => {

        if (!config.baseURL) {
           return config;
        }

        const session = await Auth.currentSession();

        const token = session.getIdToken().getJwtToken();
        config.headers['Authorization'] = token;
        return config;
    });
};