import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import UserProfileDashboard from '../../pages/UserProfileDashboard';
import Dashboard from '../../pages/Dashboard';
import { useAmzAtlasContext } from '../AmzAtlasContext/AmzAtlasContext';
import { UserInitializer } from '../UserInitializer/UserInitializer';
import { observer } from 'mobx-react-lite';
import UserManagement from '../../pages/UserManagement';
import { AuthenticatedRoute } from './AuthenticatedRoute';

const Routes: React.FC = () => {
  const { usersStore } = useAmzAtlasContext();

  if (!usersStore.currentUser?.isLoaded) {
    return <UserInitializer />;
  }

  return (
    <>
      <BrowserRouter>
        <Switch>
          <Redirect from='/' exact to='/dashboard' />
          <Route path='/user-profile' component={UserProfileDashboard} />
          <Route path='/dashboard' component={Dashboard} />
          <AuthenticatedRoute
            currentUser={usersStore.currentUser}
            path='/usermanagement'
            component={UserManagement}
          />
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default observer(Routes);
