import React, { useState } from 'react';
import { Container, Divider, Grid, GridColumn } from 'semantic-ui-react';
import AmzBackground from '../Components/AmzBackground';
import AmzAtlasHeader from '../Components/Layout/AmzAtlasHeader';
import ChangeEmail from '../Components/Layout/UserProfile/ChangeEmail';
import ChangePassword from '../Components/Layout/UserProfile/ChangePassword';
import Loading from '../Components/Loading/Loading';

const UserProfileDashboard: React.FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      <AmzAtlasHeader />
      <AmzBackground>
        <Loading active={isLoading}>
          <Container text>
            <Grid columns={1}>
              <GridColumn>
                <Divider />
                <ChangePassword setIsLoading={setIsLoading} />
              </GridColumn>
              <GridColumn>
                <Divider />
                <ChangeEmail setIsLoading={setIsLoading} />
              </GridColumn>
            </Grid>
          </Container>
        </Loading>
      </AmzBackground>
    </>
  );
};

export default UserProfileDashboard;
