import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { IUser } from '../../models/User';

export interface IAuthenticatedRouteProps extends RouteProps {
  currentUser: IUser;
}

export const AuthenticatedRoute: React.FC<IAuthenticatedRouteProps> = (
  props: IAuthenticatedRouteProps
) => {
  if (props.currentUser.isExecutive ||  props.currentUser.isAtlasUser) {
    return (
      <>
        <Route {...props} />
      </>
    );
  }
  return <Redirect to='/' />;
};
