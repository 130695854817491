import React from 'react';
import { Auth } from 'aws-amplify';
import { Grid, Segment, Menu, Image } from 'semantic-ui-react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import logo from '../../logo_Nav.png';

import { colors } from '../../styles';
import { useAmzAtlasContext } from '../AmzAtlasContext/AmzAtlasContext';

const AmzAtlasHeader: React.FunctionComponent = () => {
  const location = useLocation();
  const history = useHistory();
  const { usersStore } = useAmzAtlasContext();

  const logout = async () => {
    try {
      await Auth.signOut();
    } finally {
      usersStore.clear();
      history.push('/');
    }
  };

  return (
    <Segment>
      <Grid
        container
        style={{
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: '#ffff',
          height: '110px',
        }}
      >
        <Grid.Column width={4} style={{ padding: '0' }}>
          {' '}
          <Image src={logo} style={{ width: 190 }} />
        </Grid.Column>
        <Grid.Column style={{ margin: 'auto' }}></Grid.Column>

        <Grid.Column width={6} styles={{}}>
          {' '}
          <Menu secondary pointing>
            <Menu.Item
              as={Link}
              active={location.pathname === '/dashboard'}
              to='/dashboard'
              style={{
                color: colors.text,
              }}
            >
              Dashboard
            </Menu.Item>
            {usersStore.currentUser?.canManageUsers && (<Menu.Item
              as={Link}
              active={location.pathname === '/usermanagement'}
              to='/usermanagement'
              style={{
                color: colors.text,
              }}
            >
              User Managment
            </Menu.Item>)}

            <Menu.Item
              as={Link}
              active={location.pathname === '/user-profile'}
              to='/user-profile'
              style={{ color: colors.text }}
            >
              User Profile
            </Menu.Item>
            <Menu.Item
              onClick={logout}
              to='/user-profile'
              style={{ color: colors.text }}
            >
              Sign Out
            </Menu.Item>
          </Menu>
        </Grid.Column>
      </Grid>
    </Segment>
  );
};

export default AmzAtlasHeader;
