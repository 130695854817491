import React from 'react';
import AmzAtlasAuthenticator from './Components/Authenticator/AmzAtlasAuthenticator';
import Bootstrap from './Components/Authenticator/AuthenticatorTheme';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

const AppWithAuth: React.FC = () => {
  return (
    <AmzAtlasAuthenticator usernameAttributes={'email'} theme={Bootstrap}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AmzAtlasAuthenticator>
  );
};

export default AppWithAuth;
