import React, { useState } from 'react';
import { Button, Form, Grid, Header, Input } from 'semantic-ui-react';
import {
  changeEmail,
  verifyEmail,
} from '../../../services/User/UserProfileService';
import ErrorDisplay from '../../Messaging/ErrorDisplay';
import SuccessDisplay from '../../Messaging/SuccessDisplay';
import { colors } from '../../../styles';

const ChangeEmail: React.FC<{setIsLoading: (loading: boolean) => void}> = ({setIsLoading}) => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [changeEmailSuccessMessage, setchangeEmailSuccessMessage] =
    useState('');
  const [verificationSuccessMessage, setVerificationSuccessMessage] =
    useState('');

  const [showVerify, setShowVerify] = useState(false);
  const [code, setCode] = useState('');

  const onChangeEmailClick = async () => {
    try {
      setIsLoading(true);
      setErrorMessage('');
      setchangeEmailSuccessMessage('');
      setVerificationSuccessMessage('');
      await changeEmail(email);
      setShowVerify(true);
      setchangeEmailSuccessMessage(
        'Your email has been changed. Please check your new email address for verification code.'
      );
    } catch (error) {
      setErrorMessage(error.message);
    }
    setIsLoading(false);
  };

  const onVerifyClick = async () => {
    try {
      setIsLoading(true);
      setErrorMessage('');
      setVerificationSuccessMessage('');
      await verifyEmail(code);
      setVerificationSuccessMessage('Thank you for verifying your email.');
    } catch (error) {
      setErrorMessage(error.message);
    }
    setIsLoading(false);
  };

  return (
    <>
      <Header style={{ color: 'white' }}>Change Email</Header>
      <Form>
        <Grid columns={1}>
          <Grid.Row>
            <Grid.Column>
              <Form.Field>
                <label style={{ color: 'white' }}>New Email</label>
                <Input
                  placeholder='New Email'
                  onChange={(e) => setEmail(e.target.value)}
                  fluid
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Button
                onClick={onChangeEmailClick}
                primary
                style={{ backgroundColor: colors.gray, width: 153 }}
              >
                Change Email
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row></Grid.Row>
          {showVerify && (
            <>
              <Grid.Column>
                <SuccessDisplay message={changeEmailSuccessMessage} />
              </Grid.Column>
              <Grid.Row>
                <Grid.Column>
                  <Input
                    placeholder='Code'
                    onChange={(e) => setCode(e.target.value)}
                    fluid
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Button
                    onClick={onVerifyClick}
                    primary
                    style={{ backgroundColor: colors.gray, width: 153 }}
                  >
                    Verify
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </>
          )}
          <Grid.Row>
            <Grid.Column>
              <ErrorDisplay message={errorMessage} />
              <SuccessDisplay message={verificationSuccessMessage} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </>
  );
};

export default ChangeEmail;
