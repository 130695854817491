import React, { createContext, useContext } from 'react';
import { UserService } from '../../services/User/UserService';
import { UsersStore } from '../../stores/UsersStore';
import { IAmzAtlasContext } from './IAmzAtlasContext';

const createContextDependencies = (): IAmzAtlasContext => {
  const userService = new UserService();

  const usersStore = UsersStore.create({ isLoading: false }, { userService });

  return {
    usersStore,
  };
};

export const contextDependencies = createContextDependencies();

export const amzAtlasContext = createContext(contextDependencies);

export const useAmzAtlasContext = (): IAmzAtlasContext => {
  return useContext(amzAtlasContext);
};

export const AmzAtlasContext: React.FC = (props) => {
  return (
    <amzAtlasContext.Provider value={contextDependencies}>
      {props.children}
    </amzAtlasContext.Provider>
  );
};
