import { observer } from 'mobx-react-lite';
import React from 'react';
import { Table, Button } from 'semantic-ui-react';
import { IUser } from '../../models/User';

interface UserDisplayEditProps {
    index: number;
    user: IUser;
    onEditClick: (index: number) => void;
    onDeleteClick: (index: number) => void;
}

const UsersDisplayEdit: React.FC<UserDisplayEditProps> = ({ index, user, onEditClick, onDeleteClick }) => {
    return <>
        <Table.Cell textAlign={"center"}>
            <Button primary compact basic size="small" icon="edit" onClick={() => onEditClick(index)}></Button>
            <Button negative compact basic size="small" icon="trash" onClick={() => onDeleteClick(index)}></Button>
        </Table.Cell>
        <Table.Cell>{user.email}</Table.Cell>
        <Table.Cell>{user.firstName}</Table.Cell>
        <Table.Cell>{user.lastName}</Table.Cell>
        <Table.Cell>{user.companyId}</Table.Cell>
        <Table.Cell>{user.role}</Table.Cell>
    </>
}

export default observer(UsersDisplayEdit);