export const colors = {
  dark: '#1A1E1E',
  primary: '#1C3A6A',
  accent: '#AE9142',
  gray: '#65625A',
  liteGray: '#D1CCBF',
  text: '#191D1D',
};

export const font = {
  primary: 'Avenir Next',
  secondary: 'Athelas',
};
