import React, { useState } from 'react';
import { Grid, Divider, Button, Container } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import UserComponent from '../User/UserComponent/UserComponent';
import { IUserSnapshot, User } from '../../models/User';
import UsersDisplayComponent from '../UsersDisplayComponent/UsersDisplayComponent';
import { useAmzAtlasContext } from '../AmzAtlasContext/AmzAtlasContext';
import { colors } from '../../styles';
import ErrorDisplay from '../Messaging/ErrorDisplay';
import SuccessDisplay from '../Messaging/SuccessDisplay';
import Loading from '../Loading/Loading';
import AmzBackground from '../AmzBackground';

const UserManagmetComponent: React.FC = () => {
  const { usersStore } = useAmzAtlasContext();
  const newUserSnapshot: IUserSnapshot = {
    id: '',
    role: 'Standard User',
    firstName: '',
    lastName: '',
    companyId: usersStore.currentUser?.isCompanyExecutive ? usersStore.currentUser.companyId : '',
    email: '',
  };


  const [editUser, setEditUser] = useState(User.create(newUserSnapshot));

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const onSaveClick = async () => {
    setErrorMessage('');
    setSuccessMessage('');

    try {
      await usersStore.createUser(editUser);
      setEditUser(User.create(newUserSnapshot));
      setSuccessMessage('User was created');
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  if (!usersStore.currentUser) {
    return <div>Current user not initialized.</div>
  }

  return (
    <AmzBackground>
      <Loading active={usersStore.isLoading}>
        <Container style={{paddingTop: 15}}>
          <Grid columns={1}>
            <Grid.Row>
              <Grid.Column>
                <UserComponent user={editUser} currentUser={usersStore.currentUser} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Button
                  onClick={onSaveClick}
                  style={{ backgroundColor: colors.gray }}
                  primary
                >
                  Save
          </Button>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <ErrorDisplay message={errorMessage} />
                <SuccessDisplay message={successMessage} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Divider />
                <UsersDisplayComponent users={usersStore.users || []} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Loading>
    </AmzBackground>
  );
};

export default observer(UserManagmetComponent);
