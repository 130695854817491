import React, { useState } from 'react';
import { Button, Form, Grid, Header, Input } from 'semantic-ui-react';
import { changePassword } from '../../../services/User/UserProfileService';
import ErrorDisplay from '../../Messaging/ErrorDisplay';
import SuccessDisplay from '../../Messaging/SuccessDisplay';
import { colors } from '../../../styles';

const ChangePassword: React.FC<{setIsLoading: (loading: boolean) => void}> = ({setIsLoading}) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const onSaveClick = async () => {
    setErrorMessage('');
    setSuccessMessage('');
    setIsLoading(true);

    try {
      await changePassword(currentPassword, newPassword);
      setSuccessMessage('Your password has been changed!');
    } catch (error) {
      setErrorMessage(error.message);
    }
    setIsLoading(false);
  };

  return (
    <>
      <Header style={{ color: 'white' }}>Change Password</Header>
      <Form>
        <Grid columns={1}>
          <Grid.Row>
            <Grid.Column>
              <Form.Field>
                <label style={{ color: 'white' }}>Current Password</label>
                <Input
                  placeholder='Current Password'
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  type='password'
                  fluid
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form.Field>
                <label style={{ color: 'white' }}>New Password</label>
                <Input
                  placeholder='New Password'
                  onChange={(e) => setNewPassword(e.target.value)}
                  fluid
                  type='password'
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Button
                onClick={onSaveClick}
                primary
                style={{ backgroundColor: colors.gray, width: 153 }}
              >
                Change Password
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <ErrorDisplay message={errorMessage} />
              <SuccessDisplay message={successMessage} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </>
  );
};

export default ChangePassword;
