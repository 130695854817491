import { Auth } from 'aws-amplify';

export const changePassword: (
  oldPassword: string,
  newPassword: string,
) => Promise<void> = async (oldPassword, newPassword) => {
  const user = await Auth.currentAuthenticatedUser();

  await Auth.changePassword(user, oldPassword, newPassword);
};

export const changeEmail: (newEmail: string) => Promise<void> = async (
  newEmail,
) => {
  const user = await Auth.currentAuthenticatedUser();

  await Auth.updateUserAttributes(user, { email: newEmail });
};

export const verifyEmail: (code: string) => Promise<void> = async (code) => {
  await Auth.verifyCurrentUserAttributeSubmit('email', code);
};
