import React from 'react';
import { observer } from 'mobx-react-lite';
import AmzAtlasHeader from '../Components/Layout/AmzAtlasHeader';
import 'semantic-ui-less/semantic.less';
import PowerBiLandingPage from '../Components/PowerBiLandingPage/PowerBiLandingPage';
import { useAmzAtlasContext } from '../Components/AmzAtlasContext/AmzAtlasContext';

const Dashboard: React.FC = () => {
  const { usersStore } = useAmzAtlasContext();
  return (
    <>
      <AmzAtlasHeader />
      <PowerBiLandingPage usersStore={usersStore} />
    </>
  );
};

export default observer(Dashboard);
