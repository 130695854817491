import React from 'react';
import img from '../Images/AMZAtlas-Blue-Banner.png';

const AmzBackground: React.FC = (props: any) => {
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${img})`,
          backgroundSize: 'cover',
          flex: 1,
          paddingBottom: 20
        }}
      >
        {props.children}
      </div>
    </>
  );
};

export default AmzBackground;
