import { observer } from 'mobx-react-lite';
import React from 'react';
import { Table, Button, Input, Dropdown } from 'semantic-ui-react';
import { IUser } from '../../models/User';

interface IUserDisplayCellsProps {
    index: number;
    user: IUser;
    currentUser: IUser | undefined;
    onSaveClick: (index: number) => void;
    onCancelClick: (index: number) => void
}

const UsersDisplayCells: React.FC<IUserDisplayCellsProps> = ({ index, user, currentUser, onSaveClick, onCancelClick }) => {

    const updateFromEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
        user.updateFromInputEvent(e);
    };
    // For the Dropdown
    const amzAtlasOptions = [
        { key: 1, text: 'Company Executive', value: 'Company Executive' },
        { key: 2, text: 'Atlas Executive', value: 'Atlas Executive' },
        { key: 3, text: 'Standard User', value: 'Standard User' },
        { key: 4, text: 'Atlas User', value: 'Atlas User' },
    ];

    const standardOptions = [
        { key: 1, text: 'Company Executive', value: 'Company Executive' },
        { key: 2, text: 'Standard User', value: 'Standard User' },
    ];

    const onDropDownChange = (data: any) => {
        user.updateFromDropdownEvent(data);
    };

    if (!currentUser) {
        return <div>Current User not initialized</div>;
    }

    return <>
        <Table.Cell textAlign={"center"}>
            <Button primary compact basic size="small" icon="save" onClick={() => onSaveClick(index)}></Button>
            <Button negative compact basic size="small" icon="cancel" onClick={() => onCancelClick(index)}></Button>
        </Table.Cell>
        <Table.Cell><Input name="email" value={user.email} onChange={updateFromEvent} /></Table.Cell>
        <Table.Cell><Input name="firstName" value={user.firstName} onChange={updateFromEvent} /></Table.Cell>
        <Table.Cell><Input name="lastName" value={user.lastName} onChange={updateFromEvent} /></Table.Cell>
        <Table.Cell><Input name="companyId" value={user.companyId} onChange={updateFromEvent} disabled={currentUser.isStandardUser || currentUser.isCompanyExecutive} /></Table.Cell>
        <Table.Cell><Dropdown
            name='role'
            data-testid='dropdown'
            options={currentUser.role === 'Company Executive' ? standardOptions : amzAtlasOptions}
            onChange={(e, d) => onDropDownChange(d)}
            value={user.role}
            selection
        /></Table.Cell>
    </>
}

export default observer(UsersDisplayCells);