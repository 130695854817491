import React from 'react';

import { observer } from 'mobx-react';
import { UserProps } from '../Domain/Interfaces/UserProps';
import { Form, Grid, Dropdown, Input } from 'semantic-ui-react';

const UserComponent: React.FC<UserProps> = ({ user, currentUser }) => {
  const updateFromEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    user.updateFromInputEvent(e);
  };

  // For the Dropdown
  const amzAtlasOptions = [
    { key: 1, text: 'Company Executive', value: 'Company Executive' },
    { key: 2, text: 'Atlas Executive', value: 'Atlas Executive' },
    { key: 3, text: 'Standard User', value: 'Standard User' },
    { key: 4, text: 'Atlas User', value: 'Atlas User' },
  ];

  const standardOptions = [
    { key: 1, text: 'Company Executive', value: 'Company Executive' },
    { key: 2, text: 'Standard User', value: 'Standard User' },
  ];

  const onDropDownChange = (data: any) => {
    user.updateFromDropdownEvent(data);
  };

  const getCompanyId = () => {
    if (currentUser.isCompanyExecutive || currentUser.isStandardUser) {
      return currentUser.companyId;
    }

    return user.companyId;
  }

  return (
    <>
      <Form>
        <Grid columns={3}>
          <Grid.Row>
            <Grid.Column>
              <Form.Field>
                <label style={{ color: 'white' }}>First Name</label>
                <Input
                  name='firstName'
                  placeholder='First Name'
                  value={user.firstName}
                  onChange={updateFromEvent}
                  fluid
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <label style={{ color: 'white' }}>Last Name</label>
                <Input
                  name='lastName'
                  placeholder='Last Name'
                  value={user.lastName}
                  onChange={updateFromEvent}
                  fluid
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <label style={{ color: 'white' }}>Role</label>
                <Dropdown
                  name='role'
                  data-testid='dropdown'
                  options={currentUser.role === 'Company Executive' ? standardOptions : amzAtlasOptions}
                  onChange={(e, d) => onDropDownChange(d)}
                  value={user.role}
                  selection
                  fluid
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form.Field>
                <label style={{ color: 'white' }}>Email</label>
                <Input
                  name='email'
                  placeholder='Email'
                  value={user.email}
                  onChange={updateFromEvent}
                  fluid
                />
              </Form.Field>
            </Grid.Column>
            {user.isCompanyExecutive || user.isStandardUser ? (
              <Grid.Column>
                <Form.Field>
                  <label style={{ color: 'white' }}>Company Id</label>
                  <Input
                    name='companyId'
                    placeholder='Company Id'
                    value={getCompanyId()}
                    onChange={updateFromEvent}
                    fluid
                    disabled={currentUser.isStandardUser || currentUser.isCompanyExecutive}
                  />
                </Form.Field>
              </Grid.Column>
            ) : null}
          </Grid.Row>
        </Grid>
      </Form>
    </>
  );
};

export default observer(UserComponent);
